import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import preloader from "../../static/img/preloader.svg"

import Header from "./header"
import Footer from "./footer"
import SearchBox from "./search_box"
import "../../static/css/bootstrap.min.css"
import "../../static/vendors/flaticon/flaticon.css"
import "../../static/vendors/stroke-icon/style.css"
import "../../static/css/font-awesome.min.css"
import "../../static/vendors/jqery-nice-selector/css/nice-select.css"
import "../../static/vendors/magnify-popup/magnific-popup.css"
import "../../static/vendors/revolution/css/settings.css"
import "../../static/vendors/revolution/css/layers.css"
import "../../static/vendors/revolution/css/navigation.css"
import "../../static/vendors/animate-css/animate.css"
import "../../static/vendors/owl-carousel/owl.carousel.min.css"

import "../../static/css/style.css"
import "../../static/css/responsive.css"
import "../../static/css/david.css"
import "../../static/css/tania.css"
import { LangProvider } from "../context/LangContext"

const Layout = ({ children, show_header,location }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <LangProvider>
      {show_header ? <Header location={location} siteTitle={data.site.siteMetadata.title}/> : null}
      <div className="preloader text-center">
        <img src={preloader} alt="" />
      </div>
      <main>{children}</main>
      <Footer/>
      <SearchBox/>
    </LangProvider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  show_header: PropTypes.bool
}
Layout.defaultProps = {
  show_header: true
}

export default Layout

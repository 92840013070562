import {Link} from "gatsby"
import PropTypes from "prop-types"
import React, {useContext, useState} from "react"
import CustomLink from "./link"
import LangContext from "../context/LangContext"
import f_logo from "../../static/img/f_logo.png"

const Header = (props) => {
    const {switchLang, lang} = useContext(LangContext)
    const [data, setData] = useState({})

    if (lang === "es") {
        import ("../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }

    return (

        <header className="main_menu_area">
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    {lang === "es" ?
                        <Link to="/" className="navbar-brand"><img src={f_logo} alt={"Logo"}
                                                                   height={40}/></Link> :
                        <Link to="/en" className="navbar-brand"><img src={f_logo} alt={"Logo"}
                                                                     height={40}/></Link>}

                    <div className="mobile_toggle">
                        <button
                            className="navbar-toggler"
                            type="button"
                            data-toggle="collapse"
                            data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent"
                            aria-expanded="false"
                            aria-label="Toggle navigation"
                        >
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                            <span className="icon-bar"/>
                        </button>
                    </div>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        {/*<LangSwitch path={props.location.pathname}/>*/}
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item dropdown submenu">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {data["nosotros"]}
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item dropdown submenu">
                                        <CustomLink to="/propuesta" className="nav-link">
                                            {data["propuesta"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/perfil" className="nav-link">
                                            {data["perfil"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/por_que" className="nav-link">
                                            {data["por_que"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/equipo" className="nav-link">
                                            {data["equipo"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/fundamentales" className="nav-link">
                                            {data["fundamentales"]}
                                        </CustomLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <CustomLink to="/servicios" className="nav-link">
                                    {data["servicios"]}
                                </CustomLink>
                                {/*<Link to="/servicios" className="nav-link" activeClassName="active">*/}
                                {/**/}
                                {/*</Link>*/}
                            </li>
                            <li className="nav-item dropdown submenu">
                                <CustomLink to="/clientes" className="nav-link">
                                    {data["clientes"]}
                                </CustomLink>
                            </li>
                            <li className="nav-item dropdown submenu">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {data["casos"]}
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <CustomLink to="/incursion_mercado" className="nav-link">
                                            {data["incursion_mercado"]}
                                        </CustomLink>

                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/emision_deuda_ce" className="nav-link">
                                            {data["emision_deuda_ce"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/programa_renta" className="nav-link">
                                            {data["programa_renta"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/programa_ipo" className="nav-link">
                                            {data["programa_ipo"]}
                                        </CustomLink>

                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/aumento_operatividad" className="nav-link">
                                            {data["aumento_operatividad"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/posicionamiento" className="nav-link">
                                            {data["posicionamiento"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/manejo_crisis" className="nav-link">
                                            {data["manejo_crisis"]}
                                        </CustomLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown submenu">
                                <a
                                    className="nav-link dropdown-toggle"
                                    href="#"
                                    role="button"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    {data["analisis"]}
                                </a>
                                <ul className="dropdown-menu">
                                    <li className="nav-item">
                                        <CustomLink to="/blog" className="nav-link">
                                            Blog
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/biblioteca" className="nav-link">
                                            {data["biblioteca"]}
                                        </CustomLink>
                                    </li>
                                    <li className="nav-item">
                                        <CustomLink to="/medios" className="nav-link">
                                            {data["noticias"]}
                                        </CustomLink>
                                    </li>
                                </ul>
                            </li>
                            <li className="nav-item">
                                <CustomLink to="/contacto" className="nav-link">
                                    {data["contacto"]}
                                </CustomLink>
                            </li>
                            {/*<li className="nav-item search">*/}
                            {/*<a href="#test-search" className="popup-with-zoom-anim">*/}
                            {/*<i className="fa fa-search"/>*/}
                            {/*</a>*/}
                            {/*</li>*/}
                            <li className="nav-item search">
                                {lang === "es" ?
                                    <a className={"nav-link i18n"}
                                       onClick={() => switchLang("en", props.location.pathname)}
                                    >En</a> :
                                    <a className={"nav-link i18n"}
                                       onClick={() => switchLang("es", props.location.pathname)}
                                    >Es</a>}
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
        </header>

    )
}

Header.propTypes = {
    siteTitle: PropTypes.string
}

Header.defaultProps = {
    siteTitle: ``
}

export default Header

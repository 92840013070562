import React, {useContext, useState} from "react"
import {Link} from "gatsby"
import LangContext from "../context/LangContext"
import CustomLink from "./link"
import  f_logo from "../../static/img/f_logo.png"

const Footer = () => {
    const {switchLang, lang} = useContext(LangContext)
    const [data, setData] = useState({})
    if (lang === "es") {
        import ("../messages/es")
            .then((msg) => {
                setData(msg.default)
            })
    } else {
        import ("../messages/en")
            .then((msg) => {
                setData(msg.default)
            })
    }
    return (

        <footer className="footer-area">
            <div className="footer_widget">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <div className="widget about_us_widget">
                                {lang === "es" ?
                                    <Link to="/" className="f_logo"> <img src={f_logo}
                                                                          alt="logo image"/></Link> :
                                    <Link to="/en" className="f_logo"> <img src={f_logo}
                                                                            alt="logo image"/></Link>}
                                {lang === "es" ?
                                    <p>
                                        IRStrat se enfoca en el mercado latinoamericano de renta fija y variable,
                                        poniendo a
                                        disposición de sus
                                        clientes su conocimiento especializado y amplia red de contactos con las
                                        principales instituciones
                                        financieras nacionales e internacionales para un efectivo posicionamiento.
                                    </p> :
                                    <p>
                                        IRStrat focuses on the fixed income and equity markets in Mexico, putting at the
                                        disposal of its clients a specialized knowledge and extensive contact network
                                        comprised of the most important financial institutions, both domestic and
                                        international, for an effective positioning.
                                    </p>}

                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <div className="widget widget_links">
                                <h4 className="widget_title"> {data["enlaces"]}</h4>
                                <div className="widget_inner row m0">
                                    <ul className="list_style">
                                        <li>
                                            <CustomLink to="/por_que">
                                                {data["por_que"]}
                                            </CustomLink>
                                        </li>
                                        <li>
                                            <CustomLink to="/perfil">
                                                {data["perfil"]}
                                            </CustomLink>
                                        </li>
                                        <li>
                                            <CustomLink to="/servicios">
                                                {data["servicios"]}
                                            </CustomLink>
                                        </li>
                                        <li>
                                            <CustomLink to="/blog">
                                                Blog
                                            </CustomLink>
                                        </li>
                                        <li>
                                            <CustomLink to="/contacto">
                                                {data["contacto"]}
                                            </CustomLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <div className="widget widget_links">
                                <h4 className="widget_title">  {data["servicios"]}</h4>
                                <div className="widget_inner row m0">
                                    <ul className="list_style">
                                        <li>
                                            <CustomLink to="/servicios/irstrat_1">
                                                IRStrat I
                                            </CustomLink>

                                        </li>
                                        <li>
                                            <CustomLink to="/servicios/irstrat_2">
                                                IRStrat II
                                            </CustomLink>
                                        </li>
                                        <li>
                                            <CustomLink to="/servicios/aumento_liquidez">
                                                {data["aumento_liquidez"]}
                                            </CustomLink>
                                        </li>
                                        <li>
                                            <CustomLink to="/servicios/programa_ipo_medida">
                                                {data["programa_ipo_medida"]}
                                            </CustomLink>
                                        </li>
                                        <li>
                                            <CustomLink to="/servicios/anual_sustentabilidad">
                                                {data["anual_sustentabilidad"]}
                                            </CustomLink>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-3">
                            <div className="widget widget_address">
                                <h4 className="widget_title">  {data["contacto"]}</h4>
                                <div className="widget_inner">

                                    <p>
                                        Torre Esmeralda II, Piso 10
                                        Col. Lomas de Chapultepec
                                        C.P. 11000, Ciudad de México.
                                        <br></br>
                                        <b><a href="tel:5563782805">{data["telefono"]}: (55) 6378-2805</a></b>
                                    </p>
                                    <br></br>
                                    <p>
                                        44 Wall Street,
                                        NY City, NY, <br></br>
                                        US, 10005
                                        <br></br>

                                        <b><a href="tel:+16464194004">{data["telefono"]}: +1 646 419 4004</a></b>


                                    </p>
                                    <a href="mailto:contacto@irstrat.com" className="email">
                                        contacto@irstrat.com
                                    </a>
                                    <ul className="nav social_icon">
                                        <li>
                                            <a href="https://twitter.com/irstrat" target={"_blank"}>
                                                <i className="fa fa-twitter"/>
                                            </a>
                                        </li>
                                        <li>
                                            <a href="http://www.linkedin.com/company/ir-strat" target={"_blank"}>
                                                <i className="fa fa-linkedin"/>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row m0 footer_copyright">
                <div className="container">
                    <div className="bottom_info">
                        <div className="pull-left">
                            Copyright © IRStrat {new Date().getFullYear()}. {data["derechos_reservados"]}
                        </div>
                        {/*<div className="pull-right">*/}
                        {/*{data["creado_por"]}:{" "}*/}
                        {/*<a href="http://www.irdesign.mx/" target="_blank">IRDesign</a>*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        </footer>

    )
}


export default Footer

import React from "react"

const SearchBox = () => (

  <div className="search_area zoom-anim-dialog mfp-hide" id="test-search">
    <div className="search_box_inner">
      <h3>Search</h3>
      <form className="input-group">
        <input type="text" className="form-control" placeholder="Search for..." />
        <span className="input-group-btn">
        <button className="btn btn-default" type="submit">
          <i className="icon icon-Search" />
        </button>
      </span>
      </form>
    </div>
  </div>

)


export default SearchBox
